// React Dependencies
import { initReactI18next } from 'react-i18next';

// Translation Dependencies
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Constants
const DEBUG = process.env.REACT_APP_I18N_DEBUG === 'true';
const LANGUAGES = process.env.REACT_APP_LANGUAGES.split('|');
const SAVE_MISSING = process.env.REACT_APP_I18N_SAVE_MISSING === 'true'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // See: https://www.i18next.com/overview/configuration-options
    backend: {
      // See: https://github.com/i18next/i18next-http-backend#backend-options
      addPath: '/locales/add/{{lng}}/{{ns}}',
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      // See: https://github.com/i18next/i18next-browser-languageDetector#detector-options
      order: ['path', 'htmlTag', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'subdomain']
    },
    react: {
      // See: https://react.i18next.com/latest/i18next-instance
      useSuspense: true
    },
    debug: DEBUG,
    saveMissing: SAVE_MISSING,
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: false,
    supportedLngs: LANGUAGES,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
