import React from 'react';

import Prismic from '@prismicio/client';

// React Components
import Icon from 'components/Icon';
import Container from 'components/Container';

// Images
import Logo from 'assets/images/footer/logo-footer@2x.png';

// Styles
import './SiteFooter.scss';

// Constants
const PRISMIC_ENDPOINT = process.env.REACT_APP_PRISMIC_ENDPOINT;
const PRISMIC_ACCESS_TOKEN = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;

// Prismic Client
const Client = Prismic.client(PRISMIC_ENDPOINT, {
  accessToken: PRISMIC_ACCESS_TOKEN,
});

const SiteFooter = () => {
  const [navContent, setNavContent] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.getSingle('navigation');
      if (response) {
        setNavContent(response.data);
      }
    };
    fetchData();
  }, []);
  return (
    <footer className="site-footer">
      <Container>
        <div className="site-footer__top">
          <div className="site-footer__column">
            <a
              href="https://www.msichicago.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Logo}
                className="site-footer__logo"
                alt="Museum of science + industry - Chicago"
              />
            </a>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Contact us</h3>
              <ul className="site-footer__items">
                {navContent &&
                  navContent.contact_us_links.map((link) => {
                    return (
                      <li key={link.link.url}>
                        <a
                          href={link.link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.link_text}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Discover more</h3>
              <ul className="site-footer__items">
                {navContent &&
                  navContent.discover_more_links.map((link) => {
                    return (
                      <li key={link.link.url}>
                        <a
                          href={link.link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.link_text}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Follow us</h3>
              <ul className="site-footer__socials">
                <li>
                  <a
                    href="https://www.facebook.com/msichicago"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon width={56} height={56} name="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/msichicago"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon width={56} height={56} name="twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/msichicago/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon width={56} height={56} name="instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/msichicago"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon width={56} height={56} name="youtube" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="site-footer__bottom">
          <ul className="site-footer__submenu">
            <li>
              <a
                href="https://www.msichicago.org/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.msichicago.org/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </li>
          </ul>

          <p>{navContent && navContent.copyright_notice}</p>
        </div>
      </Container>
    </footer>
  );
};

export default React.memo(SiteFooter);
