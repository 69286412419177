import React, { useCallback, useEffect } from 'react';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Prismic from '@prismicio/client';

// Dependencies
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

// React Components
import Container from 'components/Container';

// Styles
import './SiteHeader.scss';

// Constants
const PRISMIC_ENDPOINT = process.env.REACT_APP_PRISMIC_ENDPOINT;
const PRISMIC_ACCESS_TOKEN = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;

// Prismic Client
const Client = Prismic.client(PRISMIC_ENDPOINT, {
  accessToken: PRISMIC_ACCESS_TOKEN,
});

const SiteHeader = () => {
  // Local State
  const [status, setStatus] = useImmer({ open: false });

  const [navContent, setNavContent] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.getSingle('navigation');
      if (response) {
        setNavContent(response.data);
      }
    };
    fetchData();
  }, []);

  // Dynamic Classes
  const classes = ClassNames('site-header', {
    'site-header--open': status.open,
  });

  // Handlers
  const handleToggle = useCallback(() => {
    setStatus((draft) => {
      draft.open = !draft.open;
    });
  }, [setStatus]);

  const handleClose = useCallback(() => {
    setStatus((draft) => {
      draft.open = false;
    });
  }, [setStatus]);

  // Side-Effects
  useEffect(() => {
    status.open
      ? disablePageScroll(document.body)
      : enablePageScroll(document.body);
  }, [status.open]);

  // DOM
  return (
    <header className={classes}>
      <Container>
        <NavLink
          to="/"
          onClick={handleClose}
          activeClassName="is-active"
          className="site-header__logo"
        >
          <span className="u-visually-hidden">MSI - Age of Invention</span>
        </NavLink>

        <button
          type="button"
          onClick={handleToggle}
          className="site-header__hamburger button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <nav className="site-header__menu">
          <ul className="site-header__items">
            <li>
              <NavLink
                to="/program"
                onClick={handleClose}
                activeClassName="is-active"
                className="site-header__link"
              >
                Program
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                onClick={handleClose}
                activeClassName="is-active"
                className="site-header__link"
              >
                Help and FAQ
              </NavLink>
            </li>
          </ul>

          <ul className="site-header__items">
            <li>
              <NavLink
                to="/sponsors"
                onClick={handleClose}
                activeClassName="is-active"
                className="site-header__link"
              >
                Our Sponsors
              </NavLink>
            </li>
            <li>
              <a
                href={navContent && navContent.top_nav_accent_button_link.url}
                className="site-header__link site-header__link--accent"
                target={
                  navContent && navContent.top_nav_accent_button_link.target
                }
              >
                {navContent && navContent.top_nav_accent_button_text}
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
};

export default React.memo(SiteHeader);
