import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

// React Components
import SiteGrid from 'components/SiteGrid';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';

// Styles
import './App.scss';

// React Routes
const FAQ = lazy(() => import('routes/FAQ'));
const Home = lazy(() => import('routes/Home'));
const Error = lazy(() => import('routes/Error'));
const Program = lazy(() => import('routes/Program'));
const Sponsors = lazy(() => import('routes/Sponsors'));

// Translations
const i18n = `/:locale(${process.env.REACT_APP_LANGUAGES})?`;

const ScrollTop = () => {
  // Location
  const { pathname } = useLocation();

  // Side-Effects
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollTop />

      <Suspense fallback={null}>
        <div className="site-wrapper">
          <SiteGrid />
          <SiteHeader />

          <Switch>
            <Route exact path={`${i18n}`} component={Home} />
            <Route exact path={`${i18n}/faq`} component={FAQ} />
            <Route exact path={`${i18n}/program`} component={Program} />
            <Route exact path={`${i18n}/sponsors`} component={Sponsors} />
            <Route component={Error} />
          </Switch>

          <SiteFooter />
        </div>
      </Suspense>
    </Router>
  );
};

export default App;
