import React, { lazy } from 'react';

// Styles
import './Icon.scss';

// Icons
const Icons = {
  'icon-info': lazy(() => import('./Icons/Icon-Info')),
  'icon-phone': lazy(() => import('./Icons/Icon-Phone')),
  'icon-website': lazy(() => import('./Icons/Icon-Website')),
  'icon-twitter': lazy(() => import('./Icons/Icon-Twitter')),
  'icon-youtube': lazy(() => import('./Icons/Icon-Youtube')),
  'icon-facebook': lazy(() => import('./Icons/Icon-Facebook')),
  'icon-instagram': lazy(() => import('./Icons/Icon-Instagram')),
};

const Icon = ({ name, width, height }) => {
  // Dynamic Icon
  const Component = Icons[`icon-${name}`];

  // DOM
  return (
    Component &&
      <Component
        name={name}
        width={width}
        height={height}
      />
  );
};

export default React.memo(Icon);
