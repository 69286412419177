// Why Did You Render
// See: https://github.com/welldone-software/why-did-you-render#installation
import './wdyr';

// React Dependencies
import React from 'react';
import ReactDOM from 'react-dom';

// Translations
import './i18n';

// Utilities
import './utils/say-hello';

// React Application
import App from './components/App';

// Polyfills
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';

objectFitImages();
objectFitVideos();

// React Rendering
ReactDOM.render(<App />, document.querySelector('#react-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
