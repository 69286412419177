import React, { useEffect } from 'react';

// Dependencies
import queryString from 'query-string';

// Styles
import './SiteGrid.scss';

// Parse
const PARSED = queryString.parse(window.location.search);

// Constants
const DEBUG = PARSED.debug === 'true';
const GUTTER = PARSED.gutter || null;
const COLUMNS = PARSED.columns || 12;
const ENABLED = process.env.REACT_APP_DEBUG === 'true';

const SiteGrid = () => {
  // References
  const ref = React.useRef();

  // Lifecycle
  useEffect(() => {
    if (ref.current) {
      // Elements
      const grid = ref.current;
      const child = document.createElement('div');
    
      // Attributes
      child.className = 'site-grid__inner';
      
      // Build DOM
      for (let i = 0; i < COLUMNS; i++) {
        // Elements
        const column = document.createElement('span');

        // Attributes
        column.className = 'site-grid__column';
    
        // Styles
        if (i !== 0 && GUTTER) column.style.marginLeft = `${GUTTER}px`;
    
        // Build DOM
        child.appendChild(column);
      }

      // Build DOM
      grid.appendChild(child);
    }
  }, []);

  // Gatekeeper
  if (!ENABLED || !DEBUG) return null;

  // DOM
  return (
    <div ref={ref} className="site-grid"></div>
  );
};

export default React.memo(SiteGrid);
