import React from 'react';

// Styles
import './Container.scss';

const Container = props => {
  return (
    <div className="container">
      {props.children}
    </div>
  );
};

export default React.memo(Container);
